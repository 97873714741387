

class SecurityService {

    _jwtToken = ""
    _isInitialized = false;    

    constructor() {
        if (!SecurityService.instance) {
            SecurityService.instance = this;
        }

        return SecurityService.instance;
    }

    GetTokenData() {
        if (this._isInitialized)
            return this._jwtToken;
        else {
            var tokenFromLocalStorage = localStorage.getItem('token');

            if (tokenFromLocalStorage != null) {


                this._jwtToken = tokenFromLocalStorage;
                

                this._isInitialized = true;

                return this._jwtToken;
            }
        }

        return '';
    }

    logOut() {
        this._jwtToken = '';        
        this._isInitialized = false;

        localStorage.setItem('token', this._jwtToken);        
    }

    GetInstitutionCode() {
        return this._institutionCode;
    }

    Initialize(token, institutionCode, username) {
        this._jwtToken = token;
        this._institutionCode = institutionCode;
        this._username = username;

        localStorage.setItem('token', this._jwtToken);

        this._isInitialized = true;
    }

    IsInitialized() {

        if (!this._isInitialized) {
            this.GetTokenData();
        }
        return this._isInitialized && this._jwtToken !== "";
    }
}

export default SecurityService;