import { createRouter, createWebHashHistory } from 'vue-router';



const routes = [

    { path: '/login', component: () => import('@/Views/Login.vue') },
    { path: '/', component: () => import('@/Views/Home.vue') },
    { path: '/Home', component: () => import('@/Views/Home.vue') },
    { path: '/answers', component: () => import('@/Views/Answers/answers.vue')},
    { path: '/answers/add', component: () => import('@/Views/Answers/answerAdd.vue') },
    { path: '/answers/edit/:id', component: () => import('@/Views/Answers/answerEdit.vue') },
    { path: '/patients', component: () => import('@/Views/Patients/patients.vue') },
    { path: '/patients/add', component: () => import('@/Views/Patients/patientAdd.vue') },
    { path: '/patients/edit/:id', component: () => import('@/Views/Patients/patientEdit.vue') },
    { path: '/patients/deviceAdd/:id', component: () => import('@/Views/Patients/deviceAdd.vue') },
    { path: '/questionnaries', component: () => import('@/Views/Questionnaries/questionnaries.vue') },
    { path: '/treatments', component: () => import('@/Views/Treatments/treatments.vue') },
    { path: '/treatments/add', component: () => import('@/Views/Treatments/treatmentAdd.vue') },
    { path: '/treatments/edit/:id', component: () => import('@/Views/Treatments/treatmentEdit.vue') },
    { path: '/treatments/addNote/:id', component: () => import('@/Views/Treatments/treatmentNoteAdd.vue') },
    { path: '/treatments/editNote/:id', component: () => import('@/Views/Treatments/treatmentNoteEdit.vue') },
    { path: '/treatments/addQuestionnarie/:id/:mode', component: () => import('@/Views/Treatments/treatmentQuestionnarieAdd.vue') },
    { path: '/treatments/treatmentMedicalInterviewSurvey/:id/questionnarieGroupId/:guestionnarieGroupId/:treatmentId', component: () => import('@/Views/Treatments/treatmentMedicalInterviewSurvey.vue') },
    { path: '/treatments/previewQuestionnaire/:id', component: () => import('@/Views/Treatments/treatmentPreviewQuestionnaire.vue') },
    { path: '/treatments/treatmentProcess/:id', component: () => import('@/Views/Treatments/treatmentProcess.vue')},
    { path: '/medicines', component: () => import('@/Views/Medicines/medicines.vue') },
    { path: '/medicines/add', component: () => import('@/Views/Medicines/medicineAdd.vue') },
    { path: '/medicines/edit/:id', component: () => import('@/Views/Medicines/medicineEdit.vue') },
    { path: '/questionnarie/preview/:id', component: () => import('@/Views/Questionnaries/questionnariePreview.vue') },
    { path: '/questionnarie/add/', component: () => import('@/Views/Questionnaries/questionnarieAdd.vue') },
    { path: '/questionnarie/edit/:id', component: () => import('@/Views/Questionnaries/questionnarieEdit.vue') },
    { path: '/questions', component: () => import('@/Views/Questions/questions.vue') },
    { path: '/question/edit/:id', component: () => import('@/Views/Questions/questionEdit.vue') },
    { path: '/question/answers/:id', component: () => import('@/Views/Questions/questionAnswers.vue') },
    { path: '/question/add', component: () => import('@/Views/Questions/questionAdd.vue') },
    { path: '/users', component: () => import('@/Views/Users/users.vue') },
    { path: '/users/add', component: () => import('@/Views/Users/userAdd.vue') },
    { path: '/form/:messageCode', component: () => import('@/Views/TextMessageForm/textMessageForm.vue') },
    { path: '/export/:id', component: () => import('@/Views/Questionnaries/questionnarieExport.vue') },
    { path: '/registerDevice/', component: () => import('@/Views/RegisterDevice/registerDevice.vue') }

]

const router = createRouter({
    history: createWebHashHistory(),
    routes: routes
});

export default router;