
import { createApp } from 'vue'
//import { ref } from 'vue'

import App from './App.vue'
import router from './Router';
import axios from 'axios'
import './registerServiceWorker'

const app = createApp(App);

app.use(router)

axios.interceptors.response.use(response => {
  
    if (response.status == 200 && response.data.isError) {
        app.errorMessage = response.data.errorMessage;
        //$('#error-modal').modal('show')

        return response;
    }

    return response;
}, error => {

    console.log(error);

    if (error.response.status === 401) {


        router.push({ path: "/login" });

        return;
    }

    return error;
});


app.mount('#app');


