<template>
    <nav class="navbar navbar-light navbar-glass navbar-top navbar-expand" v-show="isLogged">
        <button class="btn navbar-toggler-humburger-icon navbar-toggler me-1 me-sm-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarVerticalCollapse" aria-controls="navbarVerticalCollapse" aria-expanded="false" aria-label="Toggle Navigation"><span class="navbar-toggle-icon"><span class="toggle-line"></span></span></button>
        <a class="navbar-brand me-1 me-sm-3" href="index.html"></a>
        <ul class="navbar-nav navbar-nav-icons ms-auto flex-row align-items-center">
            <li class="nav-item dropdown">
                <a class="nav-link pe-0" href="#" id="navbarDropdownUser" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <div class="avatar avatar-xl">
                        <img class="rounded-circle" src="/Images/user-avatar.png" alt="">
                    </div>
                </a>
                <div class="dropdown-menu dropdown-menu-end py-0" aria-labelledby="navbarDropdownUser">
                    <div class="bg-white dark__bg-1000 rounded-2 py-2">
                        <div class="dropdown-item" @click="logOut()">Wyloguj</div>
                    </div>
                </div>
            </li>
        </ul>
    </nav>
</template>

<script>
    import SecurityService from '../js/security.js'

    export default {
        name: 'TopNavComponent',
        data() {
            return {
                isLogged: true
            }
        },
        methods: {
            logOut() {
                const securityService = new SecurityService();

                securityService.logOut();
                this.$router.push({ path: "/login" });
            }
        },
    }
</script>