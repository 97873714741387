<template>
    <nav-component ref="navComponent"></nav-component>
    <div class="content">
        <top-nav-component ref="topNavComponent"></top-nav-component>

        <div class="d-flex align-items-center">
            <div class="d-flex align-items-center py-4">
            </div>
        </div>

        <router-view></router-view>
    </div>
    <div class="modal fade" id="error-modal" role="dialog">
        <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 500px">
            <div class="modal-content position-relative">
                <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                    <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body p-0">
                    <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light">
                        <h4 class="mb-1" id="modalExampleDemoLabel">Ups, wystąpił następujący problem:</h4>
                        <h4 class="mt-5 text-danger" id="modalExampleDemoLabel">{{errorMessage}}</h4>

                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-secondary" type="button" data-bs-dismiss="modal">Zamknij</button>
                </div>
            </div>
        </div>
    </div>
    <footer class="footer">
        <div class="row g-0 justify-content-between fs--1 mt-4 mb-3">
            <div class="col-12 col-sm-auto text-center">
                <p class="mb-0 text-600">Footer<span class="d-none d-sm-inline-block">| </span><br class="d-sm-none" /> 2023 &copy; <a>SunTrail</a></p>
            </div>
        </div>
    </footer>
</template>

<style lang="css">
    @import "lib/falcon/assets/css/OverlayScrollbars.min.css";
    @import "lib/falcon/assets/css/theme.min.css";
    @import "lib/falcon/assets/css/user.min.css";
    @import "css/dropDown.css";
    @import "css/dropzone.min.css";
    @import "css/medicalInterviewWizard.css";
    @import "css/question.css";
    @import "css/site.css";
</style>


<script>
    import TopNavComponent from "@/components/TopNavComponent.vue"
    import navComponent from "@/components/NavComponent.vue"
    import SecurityService from '@/js/security.js'


    export default {
        name: 'App',
        components: {
            TopNavComponent,
            navComponent,
        },
        data() {
            return {

                errorMessage: '',
            }
        },
        created: function () {
            const securityService = new SecurityService();

            if (!securityService.IsInitialized())
                this.$router.push({ path: "/login" });
        }
    }
</script>
