<template>
    <nav class="navbar navbar-light navbar-vertical navbar-expand-xl" v-show="isLogged">
        <div class="d-flex align-items-center">
            <router-link class="navbar-brand"
                         to="/home">

                <div class="d-flex align-items-center py-3">
                    <span class="font-sans-serif">SunTrail</span>
                </div>
            </router-link>
        </div>
        <div class="collapse navbar-collapse" id="navbarVerticalCollapse">
            <div class="navbar-vertical-content scrollbar">
                <ul class="navbar-nav flex-column mb-3" id="navbarVerticalNav">
                    <li class="nav-item">
                        <!-- parent pages-->
                        <a class="nav-link dropdown-indicator" href="#questionnaries" role="button" data-bs-toggle="collapse" aria-expanded="true" aria-controls="questionnaries">
                            <div class="d-flex align-items-center">
                                <span class="nav-link-icon"><span class="far fa-clipboard"></span></span><span class="nav-link-text ps-1">Kwestionariusze</span>
                            </div>
                        </a>
                        <ul class="nav false" id="questionnaries">
                            <li class="nav-item">
                                <router-link class="nav-link"
                                             to="/questionnaries">
                                    <div class="d-flex align-items-center">
                                        <span class="nav-link-text ps-1">Kwestionariusze</span>
                                    </div>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link class="nav-link"
                                             to="/questions">
                                    <div class="d-flex align-items-center">
                                        <span class="nav-link-text ps-1">Pytania</span>
                                    </div>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link class="nav-link"
                                             to="/answers">
                                    <div class="d-flex align-items-center">
                                        <span class="nav-link-text ps-1">Odpowiedzi</span>
                                    </div>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <!-- parent pages-->
                        <a class="nav-link dropdown-indicator" href="#treatments" role="button" data-bs-toggle="collapse" aria-expanded="true" aria-controls="treatments">
                            <div class="d-flex align-items-center">
                                <span class="nav-link-icon"><span class="fas fa-book-medical"></span></span><span class="nav-link-text ps-1">Kuracje</span>
                            </div>
                        </a>
                        <ul class="nav false" id="treatments">
                            <li class="nav-item">
                                <router-link class="nav-link"
                                             to="/patients">
                                    <div class="d-flex align-items-center">
                                        <span class="nav-link-text ps-1">Pacjenci</span>
                                    </div>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link class="nav-link"
                                             to="/treatments">
                                    <div class="d-flex align-items-center">
                                        <span class="nav-link-text ps-1">Kuracje</span>
                                    </div>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link class="nav-link"
                                             to="/medicines">
                                    <div class="d-flex align-items-center">
                                        <span class="nav-link-text ps-1">Lekarstwa</span>
                                    </div>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <!-- parent pages-->
                        <a class="nav-link dropdown-indicator" href="#users" role="button" data-bs-toggle="collapse" aria-expanded="true" aria-controls="users">
                            <div class="d-flex align-items-center">
                                <span class="nav-link-icon"><span class="fas fa-users"></span></span><span class="nav-link-text ps-1">Użytkownicy</span>
                            </div>
                        </a>
                        <ul class="nav false" id="users">
                            <li class="nav-item">
                                <router-link class="nav-link"
                                             to="/users">
                                    <div class="d-flex align-items-center">
                                        <span class="nav-link-text ps-1">Użytkownicy</span>
                                    </div>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
  //  import { useRoute } from 'vue-router';

    export default {
        name: 'navComponent',
        data() {
            return {
                isLogged: true
            }
        },
    }
</script>